<template>
  <div v-if="type==='price' || !hideCondition"
    class="filter-tab pt-4"
    :class="{ 'search-result': isSearchPage, 'set-background': (device === 'mobile' && !isLanding), 'side-panel': type === 'side' }"
  >
    <div class="container" v-if="type==='tabs' || type==='side'">
      <div class="row">
        <div class="col-12 filter-Wrap1 theme1" :class="{'mb-1': isOdysseySite}">
          <b-tabs card fill @input="changeCategory" v-model="tabIndex">
            <b-tab
              v-for="(cate, index) in showingCategories"
              :key="index"
              :active="index === tabIndex"
            >
              <template #title>
                {{ cate.name[lang] }}
              </template>
              <!-- <div v-if="isLoading" class="loading-message">
                {{ $t('search-tab.loading-message') }}
              </div>
              <ContentLoading v-if="isLoading" type="search-tab" /> -->
              <b-card-text v-if="activeCategory && activeCategory.dealTypeCode === 'vacation_pack'">
                <SearchTabFH
                  :category="activeCategory"
                  :agentList="agentList"
                  v-if="tabIndex === index"
                  :type="device === 'mobile' ? 'mobile' : type"
                  @closeSearchMobile="closeSearchMobile" />
              </b-card-text>
              <b-card-text v-if="activeCategory && activeCategory.dealTypeCode === 'Flight_Only'">
                <SearchTab
                  :category="activeCategory"
                  :agentList="agentList"
                  v-if="tabIndex === index"
                  :type="device === 'mobile' ? 'mobile' : type"
                  @closeSearchMobile="closeSearchMobile" />
              </b-card-text>
              <b-card-text v-else-if="activeCategory && activeCategory.dealTypeCode === 'Organize_tour_packages'">
                <SearchTabOT
                  :category="activeCategory"
                  :agentList="agentList"
                  v-if="tabIndex === index"
                  :type="device === 'mobile' ? 'mobile' : type"
                  @closeSearchMobile="closeSearchMobile" />
              </b-card-text>
              <!-- <b-card-text v-else-if="activeCategory && activeCategory.dealTypeCode === 'Flight_Only'">
                <SearchTabFOD
                  :category="activeCategory"
                  v-if="tabIndex === index"
                  :type="device === 'mobile' ? 'mobile' : type"
                  @closeSearchMobile="closeSearchMobile" />
              </b-card-text> -->
            </b-tab>

            <!-- <b-tab :key="hotelOnly.sort" :active="tabIndex === 3">
              <template #title>
                <img :src="images[4]" alt="hotel-only" class="img-fluid" />
                {{ hotelOnly.name[lang] }}
              </template>
              <div v-if="isLoading" class="loading-message">
                {{ $t('search-tab.loading-message') }}
              </div>
              <ContentLoading v-if="isLoading" type="search-tab" />
              <b-card-text v-else>
                <SeatchTabHO :category="hotelOnly" v-if="tabIndex === 3" />
              </b-card-text>
            </b-tab> -->
          </b-tabs>
        </div>
      </div>
    </div>
    <div class="container" v-else-if="type==='price'">
      <div class="row">
        <div class="col-12 filter-Wrap1">
          <b-tabs card fill v-model="tabIndex" @input="changeCategory">
            <b-tab
              v-for="(cate, index) in showingCategories"
              :key="index"
              :active="index === tabIndex"
            >
              <template #title>
                {{ cate.name[lang] }}
              </template>

              <b-card-text>
                <price-plan-search-box :category="cate" v-if="activeCategory && activeCategory.dealTypeCode === cate.code"/>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import TemplateSearchTabs from './TemplateSearchTabs';

export default {
  name: 'SearchTabsTheme1',
  mixins: [TemplateSearchTabs],
  components: {
    BTabs,
    BTab,
    BCardText,
    SearchTab: () => import('@/components/search/searchTab/SearchTabTheme1'),
    SearchTabFH: () => import('@/components/search/searchTabFH/SearchTabFHTheme1'),
    SearchTabOT: () => import('@/components/search/searchTabOT/SearchTabOTTheme1'),
    // SearchTabFOD: () => import('./SearchTabFOD'),
    // SeatchTabHO: () => import('./SearchTabHO'),
    // ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PricePlanSearchBox: () => import('@/components/pricePlan/PricePlanSearchBox'),
  },
};
</script>

<style>
/** side bar search panel style */
.side-panel .container{
  padding: 0px;
}
.side-panel .container .tab-pane.card-body {
  padding: 0;
}
.side-panel.filter-tab .tabs .card-header {
  padding-top: 0;
}
.side-panel.filter-tab form button.btn.btn-search {
  color: #343a40;
}
.side-panel.filter-tab .tabs .card-header ul.nav.nav-tabs.card-header-tabs.nav-fill {
  flex-wrap: nowrap;
}

.side-panel.filter-tab .nav-tabs .nav-link {
  display: grid;
  flex-wrap: wrap;
  line-height: 14px;
  align-items: center;
  justify-content: unset;
}
.side-panel.filter-tab .nav-fill .nav-item {
  background-color: rgba(188, 225, 253, 0.5);
}
.side-panel.filter-tab .nav-fill .nav-item .nav-link{
  padding: 0.5rem 0rem;
}

.mobile_form {
  display: none;
}
/* @media (min-width: 1200px) {
  .container {
    max-width: 1400px;
  }
} */
.filter-tab form .input-group-append .input-group-text,
.sportspage_area form .input-group-append .input-group-text,
.boxSideSearch .input-group-append .input-group-text {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.filter-tab .input-group > .form-control:not(:last-child),
.sportspage_area .input-group > .form-control:not(:last-child),
.boxSideSearch .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}
.filter-tab .nav-tabs .nav-link.active{
  color: #1993C8;
  background-color: transparent;
  border-color: transparent;
}
.filter-tab .nav-tabs .nav-item .nav-link {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  padding: 30px 20px;
  border: none;
  position: relative;
  margin: 0 30px;
}
.filter-tab .nav-tabs .nav-item .nav-link.active:after {
    opacity: 1;
}
.filter-tab .nav-tabs .nav-item .nav-link:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #1993C8;
    box-shadow: 0px 3px 6px #1993C8;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -1px;
    opacity: 0;
}
.filter-tab .nav-tabs .nav-link.active img {
  filter: drop-shadow(2px 4px 6px rgb(255, 255, 255));
}
.filter-tab .nav-fill > .nav-link,
.filter-tab .nav-fill .nav-item {
  background-color: transparent;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  flex: unset;
}
.filter-tab .nav-tabs .nav-link {
  color: black;
}
.filter-tab .tab-content {
  background-color: #fff;
}
.filter-tab .tabs .card-header {
  padding: 0.7rem 0.6rem;
  padding-top: 0px;
}
.filter-tab .tabs .card-header ul.nav.nav-tabs.card-header-tabs.nav-fill {
  padding: 0;
  border-bottom: 1px solid #707070;
  justify-content: center;
}

.tab-content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1.25rem;
}

.filter-tab .nav-tabs .nav-link {
  justify-content: space-between;
  display: -webkit-inline-box;
  align-items: center;
  float: right;
  width: fit-content;
}

.filter-Wrap1 .tabs {
  box-shadow: 0px 3px 40px rgb(0 0 0 / 13%);
  background-color: #ffffff;
  border-radius: 20px;
}

.theme1 .boxSideSearch {
  background-color: #fff;
}

@media (max-width: 767px) and (min-width: 480px) {
  .filter-tab .nav-tabs .nav-link {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .filter-tab .nav-fill > .nav-link,
  .filter-tab .nav-fill .nav-item {
    width: fit-content;
    flex: unset;
  }
}
@media (max-width: 768px) {
  .filter-tab .input-group {
    overflow: unset;
  }
  .filter-tab .nav-tabs .nav-link {
    font-size: 14px;
    padding: 20px 16px;
    margin: 0 15px;
  }
}
@media (max-width: 479px) {
  .filter-tab .tabs .card-header ul.nav.nav-tabs.card-header-tabs.nav-fill {
    flex-wrap: nowrap;
  }
  .filter-tab .nav-tabs .nav-link {
    display: grid;
    flex-wrap: wrap;
    line-height: 14px;
    align-items: center;
    justify-content: unset;
  }
  .banner-area .filter-tab .filter-Wrap1 .card-header img {
    margin: auto;
  }

  .banner-area .search-result.filter-tab {
    display: none;
  }
  .loading-message {
    font-size: 1.3rem;
  }
  .filter-tab .nav-tabs .nav-item .nav-link {
    font-size: 14px;
    padding: 13px 7px;
    margin: 0px 3px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dropdown-menu {
    width: 100%;
  }
}
</style>
<style scoped>
  .img-fluid {
    width: 32px;
    margin-left: 35px;
  }
  .set-background{
    min-height: 350px;
    background: url('https://ecdn.speedsize.com/05b4c650-ae31-47ac-b679-5cd058f0cc68/https://www.flying.co.il/assets/img/banner_landing.jpg');
  }
</style>
